<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <RoleForm @submitForm="submitForm" :role_id="role_id"></RoleForm>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "RoleEdit",
  components: {
    Loading: () => import("vue-loading-overlay"),
    RoleForm: () => import("./Form"),
  },
  data() {
    return {
      is_call_api: false,
    };
  },
  computed: {
    role_id: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    async submitForm(data) {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.post("prep-app/role/" + vm.role_id, data)
        .then(function (res) {
          if (res.status == 202) {
            vm.is_call_api = false;
            vm.$toasted.success("Submit successfully!!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.$router.push({ name: "RoleList" });
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style></style>
